import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`There are nearly 33,000 Alexa Skills available today. More than 6,800 have been added since the beginning of the year, and 1,500+ in the last month alone.`}</p>
    <p>{`We continue to be big believers in voice – it’s the natural evolution of human computer interaction. Little kids already know how to interact with these devices. We see this with our own families and friends.`}</p>
    <p>{`We previously looked at `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/2017/11/21/the-impact-of-alexa-and-google-home-on-consumer-behavior/"
      }}>{`user behavior of Alexa devices`}</a>{`, and users are overwhelming happy with the devices and see them as behavior changing.`}</p>
    <p>{`At the same time we continue to see increased demand from brands – spanning a variety of use cases from media/entertainment, to CPG, retail, automotive, pharmaceuticals, and much more.`}</p>
    <h2>{`Alexa, how does my Skill rank?`}</h2>
    <p>{`We took a look at the current ratings of skills.`}</p>
    <p>{`Nearly 60% of skills do not have any ratings, and roughly 17% have only one review. In fact, if you look at the ratings distribution, there are two big spikes at one star and five star reviews. Those are primarily due to only one rating. Nearly 75% of the one star reviews are from one rating, and 65% of the five star reviews are from one rating.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/05/02124844/rating-distribution-all.png",
        "alt": null
      }}></img></p>
    <p>{`If we remove the Skills with only one review, we start to see a pattern emerge.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/05/02124846/rating-distribution-gt-one.png",
        "alt": null
      }}></img></p>
    <p>{`However, it turns out 95% of Skills have nine or less reviews. If we remove those and focus on the top 5%, we start to see an interesting distribution with one peak around three stars and another in the higher fours.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/05/02124845/rating-distribution-gt-nine.png",
        "alt": null
      }}></img></p>
    <h2>{`Alexa, what categories are popular?`}</h2>
    <p>{`We also looked at the number of Skills and average ratings by category.`}</p>
    <p>{`Based on the number of Skills available, Games continue to lead with over 7,600 Skills on the platform. Education, News, and Music categories are next with about 4,300-4,500 Skills each.`}</p>
    <p>{`Not all of those have ratings. In fact only 39% of Games Skills have ratings, whereas about 36% of Education, 31% of News, and 39% of Music Skills have ratings.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/05/02124847/skills-by-category-rated-dark.png",
        "alt": null
      }}></img></p>
    <p>{`In terms of ratings, Music continues to lead all other categories, with an average rating of 4.2 stars. Games average about 3.6 stars. At the lower end, Food `}{`&`}{` Drink average about 3 stars and Utilities 2.8 stars.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/05/02124840/category-ratings.png",
        "alt": null
      }}></img></p>
    <h2>{`Alexa, what about recommended Skills?`}</h2>
    <p>{`Alexa has a few more categories for finding new Skills – including Staff picks and the Latest Skills.`}</p>
    <p>{`It’s interesting seeing the average star ratings for these recommendations. For the most part they tend to be higher, although there are some that are relatively average, like the recommended Smart Home Skills at 3.2 stars. The staff picks tend to be good though at 4.2 stars.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/05/02125118/recommended-category-ratings.png",
        "alt": null
      }}></img></p>
    <h2>{`Alexa, what are the most rated Skills?`}</h2>
    <p>{`In regards to the most rated Skills, ambient sound type of Skills from a variety of companies continue to be some of the most rated, as well as highest rated skills. Kasa from TP-Link continues to be a highly rated Skill, but the ratings tend to not be so hot, given the 2.7 stars.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/05/02124843/most-rated-apr2018.png",
        "alt": null
      }}></img></p>
    <h2>{`Alexa, what’s new?`}</h2>
    <p>{`We looked at the Skills with the most new ratings during April as well as new Skills added during April.`}</p>
    <p>{`Similarly with the overall most rated Skills, many of the Skills that received the most ratings during April tend to be ambient sound type of Skills. There are some new ones emerging though, like the `}<a parentName="p" {...{
        "href": "https://www.amazon.com/www-asklifebot-com-Find-My-Phone/dp/B076PHYQD2"
      }}>{`Find my Phone`}</a>{` skill.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/05/02124842/most-new-ratings-apr2018.png",
        "alt": null
      }}></img></p>
    <p>{`In regards to new Skills, some of the trending ones based on number of ratings are games like `}<a parentName="p" {...{
        "href": "https://www.amazon.com/Activision-Publishing-Call-of-Duty%C2%AE/dp/B07C8H1VVV/"
      }}>{`Call of Duty`}</a>{`, Amazon’s `}<a parentName="p" {...{
        "href": "https://www.amazon.com/Synchrony-Financial-Store-Card/dp/B079HDBYD2"
      }}>{`Store Card`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.amazon.com/QPAmerica-Smarty-Pants/dp/B07BSNYBRW"
      }}>{`Smarty Pants`}</a>{` trivia, and `}<a parentName="p" {...{
        "href": "https://www.amazon.com/HerMoney-Media-Radio/dp/B07BZXV637"
      }}>{`HerMoney Radio`}</a>{` financial advice by Jean Chatzky.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/05/02124844/new-skills-april.png",
        "alt": null
      }}></img></p>
    <p>{`While voice assistants are still a relatively new space, there are a lot of exciting developments and new Skills being created every day. We look forward to what the future has in store.`}</p>
    <h2>{`Get Started`}</h2>
    <p><a parentName="p" {...{
        "href": "https://reports.dashbot.io/signup"
      }}>{`Sign up`}</a>{` to get started with Dashbot Analytics!`}</p>
    <h2>{`About Dashbot`}</h2>
    <p><em parentName="p"><a parentName="em" {...{
          "href": "https://www.dashbot.io"
        }}>{`Dashbot`}</a>{` is a conversational analytics platform that enables brands and developers to increase engagement, acquisition, and monetization through actionable data and tools.`}</em></p>
    <p><em parentName="p">{`In addition to traditional analytics like engagement and retention, we provide chatbot and voice specific metrics including sentiment analysis, conversational analytics, and the full chat session transcripts.`}</em></p>
    <p><em parentName="p">{`We also have tools to take action on the data, like our live person take over of chat sessions and push notifications for re-engagement.`}</em></p>
    <p><em parentName="p">{`We support Facebook Messenger, Alexa, Google Home, Slack, Twitter, Kik, SMS, and any other conversational interface.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      